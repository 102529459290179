.react-datepicker {
  font-size: 1.5rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 5px !important;
}

.react-datepicker__navigation {
  top: 5px !important;
  /* overflow: visible !important; */
  /* line-height: 1.9rem !important; */
  height: 30px !important;
  width: 30px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

/* .react-datepicker-popper {
  z-index: 100 !important;
} */
.react-datepicker__navigation--next {
  background: url(../../assets/icons/right_arrow.png);
  width: 19px;
  height: 19px;
  border: none;
}

.react-datepicker__navigation--previous {
  background: url(../../assets/icons/left_arrow.png);
  width: 19px;
  height: 19px;
  border: none;
}
